import { render, staticRenderFns } from "./contactUsNew.vue?vue&type=template&id=65933192&scoped=true"
import script from "./contactUsNew.vue?vue&type=script&lang=js"
export * from "./contactUsNew.vue?vue&type=script&lang=js"
import style0 from "./contactUsNew.vue?vue&type=style&index=0&id=65933192&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65933192",
  null
  
)

export default component.exports